import multicall from "utils/multicall";
import icoAbi from "config/abi/ico.json";
import erc20Abi from "config/abi/erc20.json";
import { getAddress } from "utils/addressHelpers";
import BigNumber from "bignumber.js";
import { simpleRpcProvider } from "utils/providers";
import { IcoConfig } from "config/types";

export const fetchIcosUserBoughtAmount = async (
  account: string,
  dataToFetch: IcoConfig[]
) => {
  try {
    const calls = dataToFetch.map((singleData) => ({
      address: getAddress(singleData.address),
      name: "boughtPerUser",
      params: [account],
    }));

    const response = await multicall(icoAbi, calls);
    return response.map((data) => new BigNumber(data[0]._hex).toJSON());
  } catch (err) {
    console.error(err);
  }
};

export const fetchIcosUserPayingTokenBalance = async (
  account: string,
  dataToFetch: IcoConfig[]
) => {
  try {
    const calls = dataToFetch.map((singleData) => ({
      address: getAddress(singleData.payingToken.address),
      name: "balanceOf",
      params: [account],
    }));

    const response = await multicall(erc20Abi, calls);
    return response.map((data) => new BigNumber(data[0]._hex).toJSON());
  } catch (err) {
    console.error(err);
  }
};

export const fetchIcosUserAllowance = async (
  account: string,
  dataToFetch: IcoConfig[]
) => {
  try {
    const calls = dataToFetch.map((singleData) => ({
      address: getAddress(singleData.payingToken.address),
      name: "allowance",
      params: [account, getAddress(singleData.address)],
    }));

    const response = await multicall(erc20Abi, calls);
    return response.map((data) => new BigNumber(data[0]._hex).toJSON());
  } catch (err) {
    console.error(err);
  }
};
