const tokens = {
  tokens: {
    name: "Torq Network",
    symbol: "tokens",
    address: {
      56: "0xb375CADa58a9D428a046eDAAD03319e91E9C0032",
      97: "0xdcE025d87B3ebCBce9F2ee8Ae683eAA6071cdB97",
    },
    decimals: 18,
  },
  TORQ: {
    name: "Tether USD",
    symbol: "TORQ",
    address: {
      56: "0x55d398326f99059fF775485246999027B3197955",
      97: "0x6F56dc5b768f0bF1E22Bb2c5c06B32675C9576a3",
    },
    decimals: 18,
  },
};

export default tokens;
