import React from "react";
import { Box, FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import {
  InputContainer,
  BalanceTypography,
} from "../../styles/components/customInput";
import { CardValueTypography } from "../../styles/pages/presale";

const CustomInput = ({ symbol, balance, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (!isNaN(Number(event.target.value)) && Number(event.target.value) >= 0) {
      onChange(event.target.value);
    }
  };

  return (
    <InputContainer>
      <FormControl variant="outlined" fullWidth>
        <Box display="flex" justifyContent="space-between">
          <CardValueTypography>Amount</CardValueTypography>
          <BalanceTypography>
            {balance >= 0
              ? `(Balance: 
              ${balance.toLocaleString("en-US", {
                maximumFractionDigits: 3,
              })} ${symbol})`
              : ""}
          </BalanceTypography>
        </Box>

        <OutlinedInput
          placeholder="0.0"
          value={value}
          onChange={handleChange}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onChange(balance);
              }}
            >
              MAX
            </InputAdornment>
          }
        />
      </FormControl>
    </InputContainer>
  );
};

export default CustomInput;
