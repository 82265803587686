import React from "react";
import { Box, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useIcosData, useTimeAnalyze } from "state/ico/hooks";
import { getAddress } from "utils/addressHelpers";
import { chainId } from "utils/web3React";
import { BLOCK_EXPLORER_URLS } from "config";
import SwitchButton from "../../components/SwitchButton";
import GameCard from "../../components/LatestGameCard";
import { SocialLink } from "../../styles/pages/common";
import {
  BodyTypography,
  Container,
  ExternalLink,
  ExternalLinksWrapper,
  InfoHeader,
  InfoWrapper,
  LatestBadge,
  SocialLinksWrapper,
} from "../../styles/pages/presale";
import { LogoLink } from "../../styles/shared/banner";
import { IcoStatus } from "state/types";

const Presale = () => {
  const listedIcos = useIcosData();

  const activeIco = listedIcos.find((ico) => ico.isActive);

  const {
    address,
    description,
    name,
    projectSiteUrl,
    whitePaperUrl,
    twitterLink,
    instaLink,
    youtubeLink,
    tgLink,
    tgGroupLink,
    sellingToken,
    startDate,
    endDate,
  } = activeIco || {};

  const { status } = useTimeAnalyze(startDate, endDate);

  return activeIco ? (
    <React.Fragment>
      {/* <SwitchButton selected='latest' /> */}

      <Container>
        <InfoWrapper>
          <InfoHeader>
            <LogoLink href={projectSiteUrl} target="_blank" underline="none">
              <img
                src={`/tokens/${getAddress(sellingToken.address)}.png`}
                alt="LOGO"
                loading="lazy"
                style={{ maxWidth: "64px" }}
              />
              <Typography color="white">{name}</Typography>
            </LogoLink>
            <LatestBadge status={status}>
              {status === IcoStatus.WAITING
                ? "Waiting"
                : status === IcoStatus.OPENED
                ? "Live"
                : "Ended"}
            </LatestBadge>
          </InfoHeader>

          <BodyTypography>{description}</BodyTypography>

          <ExternalLinksWrapper>
            <ExternalLink
              href={projectSiteUrl}
              underline="none"
              target="_blank"
            >
              Website
              <LaunchIcon />
            </ExternalLink>

            <ExternalLink href={whitePaperUrl} underline="none" target="_blank">
              Whitepaper
              <LaunchIcon />
            </ExternalLink>
          </ExternalLinksWrapper>

          <SocialLinksWrapper>
            {twitterLink && (
              <SocialLink href={twitterLink} target="_blank">
                <TwitterIcon />
              </SocialLink>
            )}
            {youtubeLink && (
              <SocialLink href={youtubeLink} target="_blank">
                <YouTubeIcon />
              </SocialLink>
            )}
            {instaLink && (
              <SocialLink href={instaLink} target="_blank">
                <InstagramIcon />
              </SocialLink>
            )}
            {tgLink && (
              <SocialLink href={tgLink} target="_blank">
                <TelegramIcon />
              </SocialLink>
            )}
            {tgGroupLink && (
              <SocialLink href={tgGroupLink} target="_blank">
                <TelegramIcon />
              </SocialLink>
            )}
          </SocialLinksWrapper>

          <Box mb={1}>
            <ExternalLink
            href="https://bscscan.com"
              // href={`${getAddress(BLOCK_EXPLORER_URLS)}/address/${getAddress(
              //   address
              // )}`}
              underline="none"
              target="_blank"
            >
              View contract
              <LaunchIcon />
            </ExternalLink>
          </Box>
        </InfoWrapper>

        <Box>
          <GameCard {...activeIco} />
        </Box>
      </Container>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default Presale;
