import { Box, styled } from '@mui/material'

export const ProgressContainer = styled(Box)({
  flexGrow: 1,

  '.MuiLinearProgress-root': {
    backgroundColor: '#bfbfbf',
  },

  '.MuiLinearProgress-bar': {
    background: 'linear-gradient(259.19deg, #5A8EEC 0.58%, #D324D5 98.12%)'

  }
})
