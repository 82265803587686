import React, { useState } from "react";
import { Box } from "@mui/material";
import { DeserializedIcoProps, IcoStatus } from "state/types";
import { getAddress } from "utils/addressHelpers";
import { getBalanceNumber, getDecimalAmount } from "utils/formatBalance";
import { BIG_ZERO } from "utils/bigNumber";
import CustomInput from "../CustomInput";
import {
  BuyButton,
  CardLabelTypography,
  CardValueTypography,
} from "../../styles/pages/presale";
import { useWeb3React } from "@web3-react/core";
import ConnectModal from "components/ConnectModal";
import useApproveIco from "hooks/ico/useApproveIco";
import { useAppDispatch } from "state";
import {
  fetchIcosPublicDataAsync,
  fetchIcosUserAllowanceAsync,
  fetchIcosUserBalanceAsync,
  fetchIcosUserBoughtAmountAsync,
} from "state/ico";
import { useTimeAnalyze } from "state/ico/hooks";
import { BalanceTypography } from "styles/components/customInput";
import { useIcoContract } from "hooks/useContract";
import { buyToken } from "utils/calls/ico";
import BigNumber from "bignumber.js";
import { fetchIcosUserPayingTokenBalance } from "state/ico/fetchIcosUserData";

const LabelBox: React.FC<{ label: string; value: string }> = (props) => {
  const { label, value } = props;
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <CardLabelTypography>{label}</CardLabelTypography>
      <CardValueTypography>{value}</CardValueTypography>
    </Box>
  );
};

const UserAction: React.FC<DeserializedIcoProps> = (props) => {
  const { account } = useWeb3React();
  const {
    id,
    payingToken,
    sellingToken,
    tokenPrice,
    address,
    startDate,
    endDate,
    userData,
  } = props;
  const { allowance, boughtAmount, payingTokenBalance } = userData || {};
  const [buyingAmount, setBuyingAmount] = useState("");
  const { status } = useTimeAnalyze(startDate, endDate);

  const [connectModalOpened, openConnectModal] = useState(false);
  const [pendingTx, setPendingtx] = useState(false);

  const dispatch = useAppDispatch();
  const { onApprove } = useApproveIco(
    getAddress(payingToken.address),
    getAddress(address)
  );
  const icoContract = useIcoContract(getAddress(address));

  return (
    <>
      <ConnectModal
        open={connectModalOpened}
        handleClose={() => {
          openConnectModal(false);
        }}
      />
      {status !== IcoStatus.FINISHED && (
        <>
          <CustomInput
            symbol={payingToken.symbol}
            balance={getBalanceNumber(payingTokenBalance, payingToken.decimals)}
            value={buyingAmount}
            onChange={(value) => setBuyingAmount(value)}
          />
          {account && Number(buyingAmount) > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <BalanceTypography>
                (You will receive{" "}
                {(
                  Number(buyingAmount) /
                  getBalanceNumber(tokenPrice, payingToken.decimals)
                ).toLocaleString("en-US", {
                  maximumFractionDigits: 3,
                })}{" "}
                {sellingToken.symbol})
              </BalanceTypography>
            </Box>
          )}
          {!account ? (
            <BuyButton
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                openConnectModal(true);
              }}
            >
              Connect Wallet
            </BuyButton>
          ) : allowance && allowance.isGreaterThan(BIG_ZERO) ? (
            <BuyButton
              variant="contained"
              size="large"
              fullWidth
              disabled={
                pendingTx ||
                !Number(buyingAmount) ||
                status !== IcoStatus.OPENED
              }
              onClick={async () => {
                try {
                  setPendingtx(true);
                  await buyToken(
                    icoContract,
                    getDecimalAmount(
                      new BigNumber(buyingAmount),
                      payingToken.decimals
                    ).toFixed(),
                    payingToken.symbol === "BNB"
                  );
                  setBuyingAmount("");
                  dispatch(fetchIcosPublicDataAsync([id]));
                  dispatch(fetchIcosUserBalanceAsync({ account, ids: [id] }));
                  dispatch(
                    fetchIcosUserBoughtAmountAsync({ account, ids: [id] })
                  );
                } catch (err) {
                  console.error(err);
                } finally {
                  setPendingtx(false);
                }
              }}
            >
              Buy with {payingToken.symbol}
            </BuyButton>
          ) : (
            <BuyButton
              variant="contained"
              size="large"
              fullWidth
              disabled={pendingTx}
              onClick={async () => {
                try {
                  setPendingtx(true);
                  await onApprove();
                  dispatch(fetchIcosUserAllowanceAsync({ account, ids: [id] }));
                } catch (err) {
                  console.error(err);
                } finally {
                  setPendingtx(false);
                }
              }}
            >
              Approve
            </BuyButton>
          )}
        </>
      )}
    </>
  );
};

export default UserAction;
