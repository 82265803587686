import { Box, styled } from '@mui/material'
import { CardLabelTypography } from '../../styles/pages/presale'

export const InputContainer = styled(Box)({
  marginTop: '8px',
  
  '.MuiOutlinedInput-root.MuiInputBase-root': {
    paddingRight: '10px',
  },

  '.MuiOutlinedInput-input.MuiInputBase-input': {
    padding: '10px 8px',
  },

  '.MuiInputAdornment-root .MuiTypography-root': {
    fontSize: '14px',
  },
})

export const BalanceTypography = styled(CardLabelTypography)({
  fontSize: '12px',
})
