import { Link, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'

export const ItemContainer = styled(Box)({
  border: '2px solid var(--banner-bg-color)',
  borderRadius: '6px',
  padding: '15px 0',

  '&:hover': {
    background: '#b7cbcb'
  },

  // '&:hover p': {
  //   color: 'var(--primary-color)',
  // }
})

export const ItemLink = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: 1.2,
  color: 'var(--banner-bg-color)',

  // '&:hover': {
  //   color: 'var(--primary-color)',
  // }
})
