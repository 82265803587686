import React from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ConnectorLocalStorageKey, ConnectorNames } from "config";
import useAuth from "hooks/useAuth";
import WalletItem from "../WalletItem";
import {
  CloseButton,
  ModalContentBox,
  NetworkContainer,
  TitleTypography,
} from "../../styles/components/connectModal";
import WalletInfo from "components/WalletInfo";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}
const WalletModal: React.FC<ModalProps> = (props) => {
  const { open, handleClose } = props;
  const { logout } = useAuth();

  const handleDisconnect = () => {
    logout();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContentBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "12px",
          }}
        >
          <TitleTypography>Your Wallet</TitleTypography>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Box>
        <WalletInfo handleDisconnect={handleDisconnect} />
      </ModalContentBox>
    </Modal>
  );
};

export default WalletModal;
