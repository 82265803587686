import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useWeb3React } from "@web3-react/core";
import ConnectModal from "../components/ConnectModal";
import {
  BodyTypography,
  ConnectWalletButton,
  LogoLink,
  TitleTypography,
} from "../styles/shared/banner";
import { truncateWalletString } from "utils";
import WalletModal from "components/WalletModal";

const Banner = () => {
  const { account } = useWeb3React();

  const [connectModalOpened, openConnectModal] = React.useState(false);
  const handleOpenConnectModal = () => openConnectModal(true);
  const handleCloseConnectModal = () => openConnectModal(false);
  const [walletModalOpened, openWalletModal] = React.useState(false);
  const handleOpenWalletModal = () => openWalletModal(true);
  const handleCloseWalletModal = () => openWalletModal(false);

  return (
    <React.Fragment>
      <ConnectModal
        open={connectModalOpened}
        handleClose={handleCloseConnectModal}
      />
      <WalletModal
        open={walletModalOpened}
        handleClose={handleCloseWalletModal}
      />

      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={3}
        >
          <LogoLink href="#" underline="none">
            <img src="/logo.png" alt="LOGO" loading="lazy" />
            <Typography color="white" display={["none", null, "block"]}>
              Torq Network
            </Typography>
          </LogoLink>
          {account ? (
            <ConnectWalletButton onClick={handleOpenWalletModal}>
              {truncateWalletString(account)}
            </ConnectWalletButton>
          ) : (
            <ConnectWalletButton onClick={handleOpenConnectModal}>
              Connect Wallet
              <ArrowRightAltIcon />
            </ConnectWalletButton>
          )}
        </Box>

        <Box className="banner" mb="80px">
          <Grid container spacing={3} ml={0} mt={0} width="100%">
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems={["center", null, "flex-start"]}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              mt={3}
              mb={6}
            >
              <TitleTypography textAlign="center" variant="h1">
                ICO Launchpad
              </TitleTypography>
              <BodyTypography
                variant="body1"
                mt={2}
                textAlign={["center", null, "left"]}
              >
                Buy new tokens directly on Torq Network Platform. Maximize your
                profit by participating in the Initial Coin Offerings.
              </BodyTypography>
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              mt={3}
            >
              <img
                src="/banner.png"
                alt="Banner"
                width="100%"
                style={{ maxWidth: "320px" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Banner;
