import React from "react";
import { Box } from "@mui/material";
import {
  CounterBox,
  ItemBox,
  NumberTypography,
  TitleTypography,
  UnitTypography,
} from "../../styles/components/counter";
import { useTimeAnalyze } from "state/ico/hooks";
import getTimePeriods from "utils/getTimePeriods";
import { IcoStatus } from "state/types";

const FormattedTimeUnit: React.FC<{ counter: number; unit: string }> = ({
  counter,
  unit,
}) => {
  return (
    <ItemBox>
      <NumberTypography>
        {counter
          ? counter.toLocaleString("en-US", { minimumIntegerDigits: 2 })
          : "00"}
      </NumberTypography>
      <UnitTypography>{unit}</UnitTypography>
    </ItemBox>
  );
};

const Counter: React.FC<{ startDate: number; endDate: number }> = ({
  startDate,
  endDate,
}) => {
  const { status, untilSec } = useTimeAnalyze(startDate, endDate);

  const { days, hours, minutes, seconds } = getTimePeriods(untilSec, true);
  // const handleChange =
  //   (prop: keyof CounterState) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setValues({ ...values, [prop]: event.target.value })
  //   }

  return status === IcoStatus.FINISHED ? (
    <></>
  ) : (
    <CounterBox>
      <TitleTypography>
        {status === IcoStatus.WAITING
          ? "Presale Starts In"
          : status === IcoStatus.OPENED
          ? "Presale Ends In"
          : "Presale Ended"}
      </TitleTypography>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <FormattedTimeUnit counter={days} unit="Days" />
        <FormattedTimeUnit counter={hours} unit="Hours" />
        <FormattedTimeUnit counter={minutes} unit="Minutes" />
        <FormattedTimeUnit counter={seconds} unit="Seconds" />
      </Box>
    </CounterBox>
  );
};

export default Counter;
