import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import IdoPage from './pages/ido'
import HistoryPage from './pages/history'

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IdoPage />} />
          {/* <Route path="/ico" element={<IdoPage />} /> */}
          {/* <Route path="/ico/history" element={<HistoryPage />} /> */}
          {/* <Route path='/' element={<Navigate replace to='/ico' />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
