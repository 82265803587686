import { Box, styled, Typography } from '@mui/material'

export const CardWrapper = styled(Box)({
  marginBottom: '56px',

  '.MuiAccordion-root': {
    backgroundColor: '#1b2a2f',
    color: 'var(--primary-color)',

    '&.Mui-expanded .MuiTypography-root': {
      color: 'var(--banner-bg-color)',
    },

    '.MuiButtonBase-root .MuiTypography-root': {
      fontFamily: 'Oswald, sans-serif',
    },

    '.MuiCollapse-root span': {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '14px',
    }

  },

  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--content-bg-color)',
  },

  '.MuiAccordionSummary-content': {
    margin: '6px 0',
  },

  '.Mui-expanded': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#000',
  },

  '.MuiAccordionSummary-root': {
    height: '60px',
  },
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '46px',
  fontWeight: 600,
  lineHeight: '50px',
  marginBottom: '24px',
  color: '#262626',
})

export const AccordionWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  
  '@media screen and (min-width: 852px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '32px',
  }
})
