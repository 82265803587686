import { Button, Link, styled, Typography } from '@mui/material'

export const LogoLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  
  '& img': {
    width: '140px',
  },

  '& p': {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '34px',
    fontWeight: 600,
    lineHeight: '34px',
  }
})

export const ConnectWalletButton = styled(Button)({
  color: '#fff',
  fontFamily: 'Oswald, sans-serif',
  textTransform: 'none',
  border: '2px solid var(--primary-color)',
  borderRadius: '20px',
  padding: '7px 12px',

  '&:hover': {
    color: 'var(--primary-color)',
  }
})

export const TitleTypography = styled(Typography)({
  color: '#18e0e7',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '60px',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: '2px',
  textTransform: 'uppercase',
})

export const BodyTypography = styled(Typography)({
  color: '#fff',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '18px',
  lineHeight: '30px',
})
