import { Box, styled, Typography } from '@mui/material'

export const CounterBox = styled(Box)({
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'center',
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: '2px',
  color: 'var(--banner-bg-color)',
  paddingBottom: '8px',
})

export const ItemBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#202020',
  borderRadius: '8px',
  width: '50px',
  height: '40px',
  padding: '8px 6px',
  color: 'var(--content-bg-color)',
})

export const NumberTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: 1.2,
})

export const UnitTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 1.4,
})
