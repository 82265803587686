import React from "react";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  FooterContainer,
  InfoBox,
  LogoGrid,
  LogoLink,
  MenuGrid,
  MenuWrapper,
  ReservedTypography,
  SocialLink,
  SocialLinkWrapper,
} from "../styles/shared/footer";

const Footer = () => {
  return (
    <FooterContainer>
      <Grid container>
        <LogoGrid item xs={12} sm={4}>
          <LogoLink href="#" underline="none">
            <img src="/logo.png" alt="LOGO" loading="lazy" />
          </LogoLink>
        </LogoGrid>

        <MenuGrid item xs={12} sm={4}>
          <Box display="flex" justifyContent="center">
            <MenuWrapper>
              <List>
                <ListItem disablePadding>
                  <Link
                    target="_blank"
                    href="mailto:support@Torq Network.world"
                    style={{ textDecoration: "none", display: 'flex', alignItems: 'center' }}
                  >
                    <ListItemIcon>
                      <MailOutlineIcon />
                    </ListItemIcon>
                    <Typography>support@torqnetwork.com</Typography>
                  </Link>
                </ListItem>
                {/* <ListItem disablePadding>
                  <ListItemIcon>
                    <PhoneIphoneIcon />
                  </ListItemIcon>
                  <Typography>+1 (551) 227-7755</Typography>
                </ListItem> */}
                <ListItem disablePadding>
                  <ListItemIcon>
                    <FmdGoodIcon />
                  </ListItemIcon>
                  <Typography>
                    Dubai
                  </Typography>
                </ListItem>
              </List>
            </MenuWrapper>
          </Box>
        </MenuGrid>
      </Grid>

      <InfoBox>
        <SocialLinkWrapper>
          <SocialLink href="https://twitter.com/Torq NetworkOfficial?t=6GgKg7sefYFsuolnG-in2w&s=09" target='_blank'>
            <TwitterIcon />
          </SocialLink>
          <SocialLink href="https://instagram.com/Torq Networkofficial?igshid=YmMyMTA2M2Y=" target='blank'>
            <InstagramIcon />
          </SocialLink>
          <SocialLink href="https://telegram.me/TheTorqNetwork" target='blank'>
            <TelegramIcon />
          </SocialLink>
          {/* <SocialLink href="https://telegram.me/Torq Network_Official#" target='blank'>
            <TelegramIcon />
          </SocialLink> */}
        </SocialLinkWrapper>
        <ReservedTypography>
          © Copyright 2022 Torq Network - All Rights Reserved
        </ReservedTypography>
      </InfoBox>
    </FooterContainer>
  );
};

export default Footer;
