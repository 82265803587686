import { Link } from "@mui/material";
import React from "react";
import { ExternalLink } from "styles/pages/presale";
import {
  ApplyButton,
  ContainerBox,
  ImageBox,
  InfoBox,
  SubtitleTypography,
  TitleTypography,
} from "../styles/shared/apply";

const ApplySection = () => {
  return (
    <ContainerBox>
      <ImageBox>
        <img src="/apply_img_bgLess.png" alt="IMG" width="100%" />
      </ImageBox>
      <InfoBox>
        <TitleTypography variant="h4">
          Want to launch your project on Torq Network Launchpad
        </TitleTypography>
        <SubtitleTypography>
          Verify your token contract and contact us to sit in Torq Rocket. Torq Rocket will help you to reach success.
        </SubtitleTypography>
        <Link
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLScFdzmzxwIkJ6exGdepbIFKsvT_MNimxaHxA5uM3I8g5LXceQ/viewform?usp=sf_link"
          style={{ textDecoration: "none" }}
        >
          <ApplyButton variant="contained">Apply to Launch</ApplyButton>
        </Link>
      </InfoBox>
    </ContainerBox>
  );
};

export default ApplySection;
