import { Grid } from "@mui/material";
import React from "react";
import {
  CardWrapper,
  CircledNumberTypography,
  ContentTypography,
  TitleTypography,
} from "../../styles/components/guideCard";

interface CardProps {
  number: number;
  title: string;
  content: string;
  image: string;
}

const GuideCard: React.FC<CardProps> = (props) => {
  const { number, title, content, image } = props;

  return (
    <CardWrapper>
      <CircledNumberTypography>{number}</CircledNumberTypography>
      <Grid container justifyContent='center'>
        <img src={image} alt="IMG" loading="lazy" className="guide_card_img" />
      </Grid>

      <TitleTypography>{title}</TitleTypography>
      <ContentTypography>{content}</ContentTypography>
    </CardWrapper>
  );
};

export default GuideCard;
