import BigNumber from "bignumber.js";
import { IcoConfig } from "config/types";

export enum IcoStatus {
  WAITING,
  OPENED,
  FINISHED,
}

export interface SerializedIcoUserData {
  boughtAmount: SerializedBigNumber;
  payingTokenBalance: SerializedBigNumber;
  allowance: SerializedBigNumber;
}

export interface DeserializedIcoUserData {
  boughtAmount: BigNumber;
  payingTokenBalance: BigNumber;
  allowance: BigNumber;
}

interface IcoPublicCoreProps {
  startDate?: number;
  endDate?: number;
}

export interface SerializedIcoProps extends IcoPublicCoreProps, IcoConfig {
  hardcap?: SerializedBigNumber;
  softcap?: SerializedBigNumber;
  maxPerUser?: SerializedBigNumber;
  minPerTx?: SerializedBigNumber;
  tokenPrice?: SerializedBigNumber;
  totalSold?: SerializedBigNumber;
  userData?: SerializedIcoUserData;
}

export interface DeserializedIcoProps extends IcoPublicCoreProps, IcoConfig {
  hardcap?: BigNumber;
  softcap?: BigNumber;
  maxPerUser?: BigNumber;
  minPerTx?: BigNumber;
  tokenPrice?: BigNumber;
  totalSold?: BigNumber;
  userData?: DeserializedIcoUserData;
}

export interface SerializedIcoState {
  data: SerializedIcoProps[];
}

export interface State {
  ico: SerializedIcoState;
}
