import { Box, styled, Typography } from '@mui/material'

export const BoxWrapper = styled(Box)({
  marginBottom: '56px',
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '46px',
  fontWeight: 600,
  lineHeight: '50px',
  marginBottom: '24px',
  color: '#262626',
  textAlign: 'center',
})

export const CardWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '8px',

  '@media screen and (min-width: 576px)': {
    gap: '24px',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@media screen and (min-width: 852px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})
