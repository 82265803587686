import { Box, Button, Grid, styled, Typography } from '@mui/material'

export const ContainerBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '32px',
  alignItems: 'center',
  borderRadius: '24px',
  overflow: 'hidden',
  marginBottom: '52px',
  background: 'linear-gradient(259.19deg, #5A8EEC 0.58%, #D324D5 98.12%)',

  '@media screen and (min-width: 768px)': {
    gridTemplateColumns: '42% 58%',
    gap: '40px',
  }
})

export const ImageBox = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  & > img {
    max-width: 320px;
  }
`

export const InfoBox = styled(Box)({
  padding: '0 24px 24px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '@media screen and (min-width: 768px)': {
    display: 'block',
    padding: 0,
    textAlign: 'start',
  },
})

export const TitleTypography = styled(Typography)({
  maxWidth: '470px',
  color: 'var(--banner-bg-color)',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '32px',
  fontWeight: 600,
  letterSpacing: '2px',
  marginBottom: '16px',
  lineHeight: 1.2,
})

export const SubtitleTypography = styled(Typography)({
  maxWidth: '554px',
  color: 'var(--banner-bg-color)',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '16px',
})

export const ApplyButton = styled(Button)({
  backgroundColor: '#1b2a2f',
  // fontFamily: '"Open Sans", sans-serif',
  fontSize: '16px',
  textTransform: 'none',
  borderRadius: '10px',
  padding: '10px 20px',

  '&:hover': {
    backgroundColor: '#1b2a2f',
    color: 'var(--primary-color)',
  },
})
