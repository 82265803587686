import React from "react";
import { Box } from "@mui/material";
import Accordion from "../components/CustomAccordion";
import {
  CardWrapper,
  AccordionWrapper,
  TitleTypography,
} from "../styles/shared/faq";

const Faq = () => {
  return (
    <CardWrapper>
      <TitleTypography>FAQs</TitleTypography>

      <AccordionWrapper>
        <Box display="flex" flexDirection="column">
          <Accordion
            title="What's the duration of ICO on Torq Network Launchpad?"
            content="It is determined while creation of the ICO by the ICO owner."
          />
          <Accordion
            title="How are tokens distributed on the Launchpad, depending on the amount of funds collected?"
            content={
              'Yes, you will get tokens immediately after you participate in the ICO.'
            }
          />
          <Accordion
            title="Is there any fee to participate?"
            content="Everyone can take part without any fee."
          />
        </Box>

        <Box display="flex" flexDirection="column">
          <Accordion
            title="Can I participate in any ICO?"
            content="Yes, anyone can participate in any ICO except the whitelisted ICOs."
          />
          <Accordion
            title="What crypto can I purchase tokens with?"
            content="Purchase of tokens is possible only with TORQ."
          />
          <Accordion
            title="Are there any requirements to participate in the ICO?"
            content="There is a requirement to have some amount of TORQ in your wallet."
          />
        </Box>
      </AccordionWrapper>
    </CardWrapper>
  );
};

export default Faq;
