import { Link, styled } from '@mui/material'

export const SocialLink = styled(Link)({
  color: '#f5f5f5',
  transition: 'all 0.3s ease 0s',

  '&:hover': {
    transform: 'scale(1.3)',
  },

  // '& svg': {
  //   width: '16px',
  //   height: '16px',
  // }
})
