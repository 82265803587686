import React from "react";
import { Box } from "@mui/material";
import dateFormat from "dateformat";
import { DeserializedIcoProps } from "state/types";
import { getAddress } from "utils/addressHelpers";
import { getBalanceNumber } from "utils/formatBalance";
import { BIG_ZERO } from "utils/bigNumber";
import CustomProgress from "../CustomProgress";
import CustomInput from "../CustomInput";
import Counter from "../Conter";
import {
  BuyButton,
  CardAmountBox,
  CardAmountTypography,
  CardHeaderBox,
  CardImageBox,
  CardLabelTypography,
  CardListBox,
  CardLogoBox,
  CardSubtitleTypography,
  CardTitleTypography,
  CardValueTypography,
  CardWrapper,
} from "../../styles/pages/presale";
import { useWeb3React } from "@web3-react/core";
import useAuth from "hooks/useAuth";
import ConnectModal from "components/ConnectModal";
import UserAction from "./UserAction";

const LabelBox: React.FC<{ label: string; value: string }> = (props) => {
  const { label, value } = props;
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <CardLabelTypography>{label}</CardLabelTypography>
      <CardValueTypography>{value}</CardValueTypography>
    </Box>
  );
};

const LatestGameCard: React.FC<DeserializedIcoProps> = (props) => {
  const { account } = useWeb3React();
  const {
    sellingToken,
    payingToken,
    startDate,
    endDate,
    hardcap,
    softcap,
    totalSold,
    minPerTx,
    maxPerUser,
    tokenPrice,
    userData,
  } = props;

  const { boughtAmount } = userData || {};

  return (
    <CardWrapper>
      <Box display="flex" pb={2}>
        <CardImageBox>
          <img
            src={`/tokens/${getAddress(payingToken.address)}.png`}
            alt={payingToken.name}
            className="token"
          />
          <CardLogoBox>
            <img
              src={`/tokens/${getAddress(sellingToken.address)}.png`}
              alt={sellingToken.name}
              width="100%"
            />
          </CardLogoBox>
        </CardImageBox>
        <CardHeaderBox>
          <CardTitleTypography variant="body2">
            ICO Pool
            {/* <HelpOutlineIcon /> */}
          </CardTitleTypography>
          <CardSubtitleTypography>
            Deposit {payingToken.symbol} - Get {sellingToken.symbol}
          </CardSubtitleTypography>
          <CardAmountBox>
            <img
              src={`/tokens/${getAddress(payingToken.address)}.png`}
              alt={payingToken.name}
              className="small-logo"
            />
            <CardAmountTypography>
              Max:{" "}
              {maxPerUser && maxPerUser.isGreaterThan(BIG_ZERO)
                ? `${getBalanceNumber(
                    maxPerUser,
                    payingToken.decimals
                  ).toLocaleString("en-US")} ${payingToken.symbol}`
                : "Unlimited"}
              , Min:{" "}
              {minPerTx && minPerTx.isGreaterThan(BIG_ZERO)
                ? `${getBalanceNumber(
                    minPerTx,
                    payingToken.decimals
                  ).toLocaleString("en-US")} ${payingToken.symbol}`
                : "Unlimited"}
            </CardAmountTypography>
          </CardAmountBox>
        </CardHeaderBox>
      </Box>
      <CardListBox>
        <Box mb={3}>
          <LabelBox
            label="Hardcap:"
            value={`${getBalanceNumber(
              hardcap,
              payingToken.decimals
            ).toLocaleString("en-US")} ${payingToken.symbol}`}
          />
          <LabelBox
            label="Softcap:"
            value={`${getBalanceNumber(
              softcap,
              payingToken.decimals
            ).toLocaleString("en-US")} ${payingToken.symbol}`}
          />
          <LabelBox
            label={`Price per ${sellingToken.symbol}`}
            value={`${getBalanceNumber(
              tokenPrice,
              payingToken.decimals
            ).toLocaleString("en-US", {
              maximumFractionDigits: 3,
            })} ${payingToken.symbol}`}
          />
          <LabelBox
            label="Start Date"
            value={dateFormat(
              new Date((startDate || 0) * 1000),
              "UTC:ddd, mmm dd, HH:MM (Z)"
            )}
          />
          <LabelBox
            label="End Date"
            value={dateFormat(
              new Date((endDate || 0) * 1000),
              "UTC:ddd, mmm dd, HH:MM (Z)"
            )}
          />
          {account && boughtAmount && (
            <LabelBox
              label={`You Bought`}
              value={`${getBalanceNumber(
                boughtAmount,
                payingToken.decimals
              ).toLocaleString("en-US", {
                maximumFractionDigits: 3,
              })} ${payingToken.symbol}`}
            />
          )}
          <LabelBox
            label="Total raised (% of target):"
            value={`${totalSold
              .div(softcap)
              .times(100)
              .toNumber()
              .toLocaleString("en-US", {
                maximumFractionDigits: 3,
                minimumFractionDigits: 2,
              })} %`}
          />
          <CustomProgress
            symbol={payingToken.symbol}
            totalRaised={getBalanceNumber(totalSold, payingToken.decimals)}
            softcap={getBalanceNumber(softcap, payingToken.decimals)}
          />
          <Counter startDate={startDate} endDate={endDate} />

          <UserAction {...props} />
        </Box>
      </CardListBox>
    </CardWrapper>
  );
};

export default LatestGameCard;
