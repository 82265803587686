import { SerializedIcoProps } from "state/types";
import multicall from "utils/multicall";
import icoAbi from "config/abi/ico.json";
import { getAddress } from "utils/addressHelpers";
import BigNumber from "bignumber.js";
import { IcoConfig } from "config/types";

const fetchSingleData = async (
  dataToFetch: IcoConfig
): Promise<SerializedIcoProps> => {
  const { address } = dataToFetch;
  const icoAddress = getAddress(address);
  const calls = [
    {
      address: icoAddress,
      name: "startDate",
    },
    {
      address: icoAddress,
      name: "endDate",
    },
    {
      address: icoAddress,
      name: "hardcap",
    },
    {
      address: icoAddress,
      name: "softcap",
    },
    {
      address: icoAddress,
      name: "maxPerUser",
    },
    {
      address: icoAddress,
      name: "minPerTx",
    },
    {
      address: icoAddress,
      name: "tokenPrice",
    },
    {
      address: icoAddress,
      name: "totalSold",
    },
  ];

  try {
    const [
      [startDate],
      [endDate],
      [hardcap],
      [softcap],
      [maxPerUser],
      [minPerTx],
      [tokenPrice],
      [totalSold],
    ] = await multicall(icoAbi, calls);

    return {
      ...dataToFetch,
      startDate: Number(startDate._hex),
      endDate: Number(endDate._hex),
      hardcap: new BigNumber(hardcap._hex).toJSON(),
      softcap: new BigNumber(softcap._hex).toJSON(),
      maxPerUser: new BigNumber(maxPerUser._hex).toJSON(),
      minPerTx: new BigNumber(minPerTx._hex).toJSON(),
      tokenPrice: new BigNumber(tokenPrice._hex).toJSON(),
      totalSold: new BigNumber(totalSold._hex).toJSON(),
    };
  } catch (err) {
    console.error(err);
  }
};

const fetchIcosPublicData = async (batchDataToFetch: IcoConfig[]) => {
  const icosPublicData = await Promise.all(
    batchDataToFetch.map(async (singleDataToFetch) => {
      const singleData = await fetchSingleData(singleDataToFetch);
      return singleData;
    })
  );
  return icosPublicData;
};

export default fetchIcosPublicData;
