/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import icosConfig from "config/ico";
import { SerializedIcoProps, SerializedIcoState } from "state/types";
import fetchIcosPublicData from "./fetchIcosPublicData";
import {
  fetchIcosUserAllowance,
  fetchIcosUserBoughtAmount,
  fetchIcosUserPayingTokenBalance,
} from "./fetchIcosUserData";

export const initialState: SerializedIcoState = {
  data: icosConfig,
};

export const fetchIcosPublicDataAsync = createAsyncThunk<
  SerializedIcoProps[],
  number[]
>("ico/fetchIcosPublicDataAsync", async (ids) => {
  const dataToFetch = icosConfig.filter((config) => ids.includes(config.id));
  const data = await fetchIcosPublicData(dataToFetch);
  return data;
});

interface IcoUserDataResponse {
  id: number;
  boughtAmount: SerializedBigNumber;
  payingTokenBalance: SerializedBigNumber;
  allowance: SerializedBigNumber;
}

export const fetchIcosUserDataAsync = createAsyncThunk<
  IcoUserDataResponse[],
  { account: string; ids: number[] }
>("ico/fetchIcosUserDataAsync", async ({ account, ids }) => {
  const dataToFetch = icosConfig.filter((config) => ids.includes(config.id));

  const boughtAmounts = await fetchIcosUserBoughtAmount(account, dataToFetch);
  const allowances = await fetchIcosUserAllowance(account, dataToFetch);
  const balances = await fetchIcosUserPayingTokenBalance(account, dataToFetch);

  return boughtAmounts.map((amount, index) => ({
    id: ids[index],
    boughtAmount: amount,
    payingTokenBalance: balances[index],
    allowance: allowances[index],
  }));
});

export const fetchIcosUserBoughtAmountAsync = createAsyncThunk<
  { id: number; boughtAmount: SerializedBigNumber }[],
  { account: string; ids: number[] }
>("ico/fetchIcosUserBoughtAmountAsync", async ({ account, ids }) => {
  const dataToFetch = icosConfig.filter((config) => ids.includes(config.id));
  const boughtAmounts = await fetchIcosUserBoughtAmount(account, dataToFetch);

  return boughtAmounts.map((amount, index) => ({
    id: ids[index],
    boughtAmount: amount,
  }));
});

export const fetchIcosUserBalanceAsync = createAsyncThunk<
  { id: number; payingTokenBalance: SerializedBigNumber }[],
  { account: string; ids: number[] }
>("ico/fetchIcosUserBalanceAsync", async ({ account, ids }) => {
  const dataToFetch = icosConfig.filter((config) => ids.includes(config.id));
  const balances = await fetchIcosUserPayingTokenBalance(account, dataToFetch);

  return balances.map((balance, index) => ({
    id: ids[index],
    payingTokenBalance: balance,
  }));
});

export const fetchIcosUserAllowanceAsync = createAsyncThunk<
  { id: number; allowance: SerializedBigNumber }[],
  { account: string; ids: number[] }
>("ico/fetchIcosUserAllowanceAsync", async ({ account, ids }) => {
  const dataToFetch = icosConfig.filter((config) => ids.includes(config.id));
  const allowances = await fetchIcosUserAllowance(account, dataToFetch);

  return allowances.map((allowance, index) => ({
    id: ids[index],
    allowance: allowance,
  }));
});

export const icosSlice = createSlice({
  name: "Icos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIcosPublicDataAsync.fulfilled, (state, action) => {
        state.data = state.data.map((stateData) => {
          const dataFetched = action.payload.find(
            (dt) => dt.id === stateData.id
          );
          return { ...stateData, ...dataFetched };
        });
      })
      .addCase(fetchIcosUserDataAsync.fulfilled, (state, action) => {
        action.payload.forEach((userDataE1) => {
          const { id } = userDataE1;
          const index = state.data.findIndex((dt) => dt.id === id);
          state.data[index] = { ...state.data[index], userData: userDataE1 };
        });
      })
      .addCase(fetchIcosUserBoughtAmountAsync.fulfilled, (state, action) => {
        action.payload.forEach((amountData) => {
          const { id } = amountData;
          const index = state.data.findIndex((dt) => dt.id === id);
          state.data[index] = {
            ...state.data[index],
            userData: { ...state.data[index].userData, ...amountData },
          };
        });
      })
      .addCase(fetchIcosUserBalanceAsync.fulfilled, (state, action) => {
        action.payload.forEach((balanceData) => {
          const { id } = balanceData;
          const index = state.data.findIndex((dt) => dt.id === id);
          state.data[index] = {
            ...state.data[index],
            userData: { ...state.data[index].userData, ...balanceData },
          };
        });
      })
      .addCase(fetchIcosUserAllowanceAsync.fulfilled, (state, action) => {
        action.payload.forEach((allowanceData) => {
          const { id } = allowanceData;
          const index = state.data.findIndex((dt) => dt.id === id);
          state.data[index] = {
            ...state.data[index],
            userData: { ...state.data[index].userData, ...allowanceData },
          };
        });
      });
  },
});

export default icosSlice.reducer;
