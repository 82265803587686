import { Box, Container, Grid, Link, styled, Typography } from "@mui/material";

export const FooterContainer = styled(Container)({
  paddingTop: '90px',
  paddingBottom: '90px',
})

export const LogoGrid = styled(Grid)({
  marginBottom: '60px',

  '@media only screen and (max-width: 767px)': {
    display: 'flex',
    justifyContent: 'center',
  }
})

export const MenuGrid = styled(Grid)({
  marginBottom: '60px',
})

export const LogoLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  
  '& img': {
    width: '100px',
  },
})

export const MenuWrapper = styled(Box)({
  color: '#fff',
  '.MuiListItem-root': {
    alignItems: 'flex-start',
    marginBottom: '18px',
  },
  '.MuiListItemIcon-root': {
    color: 'var(--content-bg-color)',
    minWidth: '30px',
  },
  '.MuiSvgIcon-root': {
    width: '16px',
    height: '20px',
  },
  '.MuiTypography-root': {
    margin: 0,
    color: 'var(--content-bg-color)',
    fontFamily: 'Oswald, sans-serif',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1.7,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
})

export const InfoBox = styled(Box)({
  textAlign: 'center',
  color: 'var(--content-bg-color)',
})

export const SocialLinkWrapper = styled(Box)({
  display: 'inline-flex',
  gap: '20px',
  marginBottom: '48px',
})

export const SocialLink = styled(Link)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '35px',
  height: '35px',
  color: 'var(--banner-bg-color)',
  background: 'var(--content-bg-color)',
  borderRadius: '50%',

  '.MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
  }
})

export const ReservedTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: 1.7,
  letterSpacing: '3px',
  textTransform: 'uppercase',
  textAlign: 'center',
})
