import { IcoConfig } from "./types";
import tokens from "./tokens";

const icos: IcoConfig[] = [
  {
    id: 0,
    address: {
      56: "0xEd90Ac589ae498a815F1e984333214e76695Dc63",
      97: "0x7f44e11Cfc1c7C06c9B57e004dED1B181CC2f8B7",
    },
    isActive: true,
    name: "Demo Project Name",
    description:
      'This is demo description: DemoCoin (DMC) is a pioneering cryptocurrency designed for the next era of digital finance. Harnessing the power of quantum-resistant cryptography, QTC prioritizes security, ensuring robust protection against emerging threats. This innovative token promises fast and scalable transactions, laying the foundation for efficient and reliable decentralized exchanges. DemoCoin envisions a future where digital assets seamlessly integrate into everyday transactions, offering users a cutting-edge experience. Join the quantum revolution and embrace the security and efficiency of DemoCoin.',
    projectSiteUrl: "https://torqnetwork.com/",
    whitePaperUrl: "https://torqnetwork.com/assets/whitepaper.pdf",
    twitterLink:
      "https://twitter.com/TheTorqNetwork",
    instaLink: "https://instagram.com/TheTorqNetwork",
    tgLink: "https://telegram.me/TheTorqNetwork",
    //tgGroupLink: "https://telegram.me/TheTorqNetwork",
    payingToken: tokens.TORQ,
    sellingToken: tokens.tokens,
  },
];

export default icos;
