import { Box, Button, Link, styled, Typography } from "@mui/material";

interface CollapsibleProps {
  expand: boolean;
}

export const CollapsibleHeader = styled(Box)<CollapsibleProps>((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "92px",
  background: 'url("/bg_presale.png")',
  backgroundSize: "100% 122px",
  borderRadius: "16px",
  cursor: "pointer",
  marginTop: "32px",
  padding: props.expand ? "0 32px 32px" : "0px 32px 0",

  img: {
    marginLeft: "auto",
  },
}));

export const CollapsibleBody = styled(Box)<CollapsibleProps>((props) => ({
  display: props.expand ? "block" : "none",
  transform: "translateY(-30px)",
  marginBottom: "-30px",
}));

export const Container = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "24px 32px",
  padding: "16px",
  backgroundColor: "#1b2a2f",
  borderRadius: "24px",
  marginTop: "32px",
  marginBottom: "56px",

  "@media screen and (min-width: 800px)": {
    padding: "32px",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

export const HistoryContainer = styled(Container)({
  marginTop: 0,
  marginBottom: 0,
});

export const InfoWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gridColumn: "1 / auto",
  paddingRight: "32px",

  // '@media screen and (min-width: 852px)': {
  //   gridColumn: '1 / 3',
  // },

  "@media screen and (min-width: 852px)": {
    gridColumn: "1 / auto",
  },
});

export const InfoHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const StatusBadge = styled(Typography)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 12px",
  borderRadius: "16px",
  fontFamily: "Oswald, sans-serif",
  fontSize: "12px",
  fontWeight: 600,
});

export const LatestBadge = styled(StatusBadge)<{ status: number }>`
  color: ${({ status }) =>
    status === 0
      ? "rgb(219, 203, 19)"
      : status === 1
      ? "rgb(59, 249, 153)"
      : "rgb(249, 59, 93)"};
  background-color: ${({ status }) =>
    status === 0
      ? "rgba(227, 183, 42, 0.16)"
      : status === 1
      ? "rgba(45, 213, 155, 0.16)"
      : "rgba(249, 59, 93, 0.16)"};
`;

export const HistoryBadge = styled(StatusBadge)({
  color: "#fff",
  backgroundColor: "rgb(14, 51, 130)",
});

export const SocialLinksWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(6, 20px)",
  columnGap: "12px",
  marginBottom: "8px",
});

export const BodyTypography = styled(Typography)({
  color: "#f5f5f5",
  fontFamily: '"Open Sans", Oswald, sans-serif',
  fontSize: "14px",
  lineHeight: 1.5,
  marginBottom: "8px",
});

export const ExternalLinksWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
});

export const ExternalLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "14px",
  lineHeight: 1.5,
  color: "var(--primary-color)",

  ".MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
  },
});

// These styles are for cards
export const CardLabelTypography = styled(Typography)({
  fontFamily: '"Open Sans", sans-serif',
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "21px",
});

export const CardValueTypography = styled(CardLabelTypography)({
  fontWeight: 600,
});

export const CardWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "16px",
  overflow: "hidden",
  background: 'linear-gradient(259.19deg, #5A8EEC 0.58%, #D324D5 98.12%)'
,
});

export const CardImageBox = styled(Box)({
  width: "98px",
  height: "98px",
  position: "relative",
});

export const CardLogoBox = styled(Box)({
  position: "absolute",
  bottom: "-4px",
  right: "-4px",
  width: "52px",
  height: "52px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
});

export const CardHeaderBox = styled(Box)({
  paddingTop: "20px",
  paddingLeft: "16px",
});

export const CardTitleTypography = styled(Typography)({
  display: "flex",
  alignItems: "flex-end",
  fontFamily: "Oswald, sans-serif",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "26px",
  color: "#262626",
  letterSpacing: "1px",

  ".MuiSvgIcon-root": {
    width: "18px",
    height: "18px",
  },
});

export const CardSubtitleTypography = styled(Typography)({
  // fontFamily: 'Oswald, sans-serif',
  fontSize: "12px",
  lineHeight: "18px",
  color: "#262626",
  marginBottom: "8px",
});

export const CardAmountBox = styled(Box)({
  display: "inline-flex",
  alignItems: "center",
  height: "20px",
  padding: "2px",
  borderRadius: "16px",
  backgroundColor: "#f5f5f5",
});

export const CardAmountTypography = styled(Typography)({
  fontFamily: '"Open Sans", sans-serif',
  fontSize: "10px",
  fontWeight: 600,
  lineHeight: 1.5,
  color: "#262626",
  paddingRight: "6px",
});

export const CardListBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "var(--content-bg-color)",
  borderRadius: "16px",
  flexGrow: 1,
  padding: "24px",
});

export const BuyButton = styled(Button)({
  marginTop: "16px",
  backgroundColor: "var(--banner-bg-color)",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "var(--banner-bg-color)",
    color: "var(--primary-color)",
  },
});
