import React from "react";
import { Box, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { ProgressContainer } from "../../styles/components/customProgress";
import { CardLabelTypography } from "../../styles/pages/presale";
import BigNumber from "bignumber.js";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface ProgressProps {
  totalRaised: number;
  softcap: number;
  symbol: string;
}

const CustomProgress: React.FC<ProgressProps> = ({
  totalRaised,
  softcap,
  symbol,
}) => {
  return (
    <ProgressContainer>
      <BorderLinearProgress
        variant="determinate"
        value={(Math.min(totalRaised / softcap, 1)) * 100}
      />
      <Box display="flex" justifyContent="space-between">
        <CardLabelTypography>
          {totalRaised.toLocaleString("en-US")} {symbol}
        </CardLabelTypography>
        <CardLabelTypography>
          {softcap.toLocaleString("en-US")} {symbol}
        </CardLabelTypography>
      </Box>
    </ProgressContainer>
  );
};

export default CustomProgress;
