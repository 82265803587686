import { Box, Button, styled, Typography } from '@mui/material'

export const ModalContentBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'var(--content-bg-color)',
  border: '1px solid var(--banner-bg-color)',
  borderRadius: '6px',
  padding: '20px 32px',
})

export const TitleTypography = styled(Typography)({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 1.5,
})

export const CloseButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  color: 'var(--banner-bg-color)',
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: 'transparent',
    color: 'var(--primary-color)',
  }
})

export const NetworkContainer = styled(Box)({
  display: 'grid',
  gridTemplateRows: 'repeat(3, 1fr)',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px 20px',
})

