import React from "react";
import GuideCard from "../components/GuideCard";
import {
  BoxWrapper,
  CardWrapper,
  TitleTypography,
} from "../styles/shared/guide";

const Guide = () => {
  return (
    <BoxWrapper>
      <TitleTypography>How to participate in Launchpad?</TitleTypography>
      <CardWrapper>
        <GuideCard
          number={1}
          title="Prepare TORQ"
          content="Have TORQ in your BEP-20 wallet before the ICO launch on Torq Network Launchpad."
          image="/img_1.png"
        />
        <GuideCard
          number={2}
          title="Deposit TORQ"
          content="Deposit TORQ in the ICO pool to buy tokens during the sale."
          image="/img_2.png"
        />
        <GuideCard
          number={3}
          title="Receive tokens"
          content="Confirm your purchased tokens after the transaction finished."
          image="/img_3.png"
        />
      </CardWrapper>
    </BoxWrapper>
  );
};

export default Guide;
