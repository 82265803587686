import { useMemo } from "react";
import { getMulticallContract, getIcoContract, getERC20Contract } from "utils/contractHelpers";
import useActiveWeb3React from "./useActiveWeb3React";

export const useMulticallContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMulticallContract(library.getSigner()), [library]);
};

export const useIcoContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getIcoContract(address, library.getSigner()), [library]);
};

export const useERC20Contract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getERC20Contract(address, library.getSigner()), [library]);
}