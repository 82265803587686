import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionContainer } from '../../styles/components/customAccordion';

type AccordionState = string | boolean

interface AccordionProps {
  title: string
  content: string
}

const CustomAccordion: React.FC<AccordionProps> = (props) => {
  const { title, content } = props
  const [expanded, setExpanded] = React.useState(false as AccordionState);

  const handleChange = (panel: AccordionState) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionContainer>
      <Accordion expanded={expanded === 'panel'} onChange={handleChange('panel')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  )
}

export default CustomAccordion
