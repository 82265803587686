import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import {
  DeserializedIcoProps,
  DeserializedIcoUserData,
  IcoStatus,
  SerializedIcoProps,
  SerializedIcoUserData,
  State,
} from "state/types";
import { useAppDispatch } from "state";
import useRefresh from "hooks/useRefresh";
import icosConfig from "config/ico";
import { fetchIcosPublicDataAsync, fetchIcosUserDataAsync } from ".";
import { BIG_ZERO } from "utils/bigNumber";
import { simpleRpcProvider } from "utils/providers";
import { getBalanceAmount, getBalanceNumber } from "utils/formatBalance";

const deserializeIcoData = (data: SerializedIcoProps): DeserializedIcoProps => {
  const {
    hardcap,
    softcap,
    maxPerUser,
    minPerTx,
    tokenPrice,
    totalSold,
    userData,
    ...rest
  } = data;
  return {
    ...rest,
    hardcap: new BigNumber(hardcap),
    softcap: new BigNumber(softcap),
    maxPerUser: new BigNumber(maxPerUser),
    minPerTx: new BigNumber(minPerTx),
    tokenPrice: new BigNumber(tokenPrice),
    totalSold: new BigNumber(totalSold),
    userData: deserializeUserData(userData),
  };
};

const deserializeUserData = (
  userData: SerializedIcoUserData
): DeserializedIcoUserData => {
  if (userData) {
    const { allowance, boughtAmount, payingTokenBalance } = userData;
    return {
      allowance: new BigNumber(allowance),
      boughtAmount: new BigNumber(boughtAmount),
      payingTokenBalance: new BigNumber(payingTokenBalance),
    };
  }
};

export const useIcosData = (): DeserializedIcoProps[] => {
  const icoState = useSelector((state: State) => state.ico);
  return icoState.data.map(deserializeIcoData);
};

export const usePollIcoData = () => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();

  useEffect(() => {
    const ids = icosConfig.map((config) => config.id);
    dispatch(fetchIcosPublicDataAsync(ids));
    if (account) {
      dispatch(fetchIcosUserDataAsync({ account, ids }));
    }
  }, [slowRefresh, account, dispatch]);
};

export const useEthBalance = () => {
  const [ethBalance, setEthBalance] = useState(BIG_ZERO);
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();

  useEffect(() => {
    const fetchEthBalance = async (account) => {
      const balance = await simpleRpcProvider.getBalance(account);
      setEthBalance(getBalanceAmount(new BigNumber(balance.toString())));
    };
    if (account) {
      fetchEthBalance(account);
    }
  }, [slowRefresh, account]);

  return ethBalance;
};

export const useTimeAnalyze = (startDate: number, endDate: number) => {
  const { baseRefresh } = useRefresh();
  const [analyzeData, setAnalyzeData] = useState<{
    untilSec: number;
    status: IcoStatus;
  }>({ untilSec: 0, status: IcoStatus.WAITING });

  useEffect(() => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    if (currentTime < startDate) {
      setAnalyzeData({
        status: IcoStatus.WAITING,
        untilSec: startDate - currentTime,
      });
    } else if (currentTime < endDate) {
      setAnalyzeData({
        status: IcoStatus.OPENED,
        untilSec: endDate - currentTime,
      });
    } else {
      setAnalyzeData({
        status: IcoStatus.FINISHED,
        untilSec: currentTime - endDate,
      });
    }
  }, [baseRefresh, startDate, endDate]);
  
  return analyzeData;
};
