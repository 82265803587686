import { Box, styled, Typography } from '@mui/material'

export const CardWrapper = styled(Box)({
  position: 'relative',
  padding: '0px 16px 24px',
  borderRadius: '24px',
  backgroundColor: '#202020',
  boxShadow: '0px 7px 20px rgb(0 0 0 / 50%)',

  '@media screen and (min-width: 576px)': {
    borderRadius: '35px',
    padding: '24px 16px',
  }
})

export const CircledNumberTypography = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '8px',
  left: '8px',
  height: '32px',
  width: '32px',
  border: '1px solid #fff',
  borderRadius: '50%',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: 1.2,
  color: '#fff',

  '@media screen and (min-width: 576px)': {
    top: '10px',
    left: '10px',
    height: '48px',
    width: '48px',
    fontSize: '24px',
    fontWeight: 600,
    borderWidth: '2px',
  },
})

export const TitleTypography = styled(Typography)({
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '21px',
  marginBottom: '8px',
  color: 'var(--primary-color)',
  textAlign: 'center',

  '@media screen and (min-width: 968px)': {
    fontSize: '18px',
  }
})

export const ContentTypography = styled(Typography)({
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '24px',
  color: 'var(--content-bg-color)',
  textAlign: 'center',

  '@media screen and (min-width: 968px)': {
    fontSize: '14px',
  },
})
