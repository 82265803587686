import React from 'react'
import { Container } from '@mui/material'
import Banner from '../../shared/Banner'
import Guide from '../../shared/Guide'
import Faq from '../../shared/Faq'
import Apply from '../../shared/Apply'
import Footer from '../../shared/Footer'
import Presale from './Presale'
import { usePollIcoData } from 'state/ico/hooks'

const IdoPage = () => {
  
  usePollIcoData()

  return (
    <React.Fragment>
      <Banner />
      <div className='content'>
        <Container>
          <Presale />
          <Guide />
          <Faq />
          <Apply />
        </Container>

      </div>
      <Footer />
    </React.Fragment>
  )
}

export default IdoPage
